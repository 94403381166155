.postboxGlobalContainer {
  position: relative;
  justify-content: center;
  align-items: flex-start;
  border-radius: 0.375rem;
  display: flex;
  flex: 1;
  flex-direction: row;
  font-family: "Inter";
}

.postboxConnectContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  flex: 1;
}

.postboxUserContainer {
  display: flex;
  position: relative;
  flex-shrink: 0;
  margin-right: 0.5rem;
}

.postboxContainer {
  display: flex;
  position: relative;
  flex: 1;
  min-width: 0px;
}

.postbox {
  overflow: hidden;
  border-radius: 0.5rem;
  border: 1px solid;
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  width: 100%;
}

.postboxInput {
  display: block;
  border-width: 0px;
  resize: none;
  background: transparent;
  padding: 12px;
  padding-bottom: 0.75rem;
  font-family: "Inter";
  outline: none;
  outline-style: none;
}
.postbox:focus-within {
  border-color: #0ea5e9 !important
}
.postboxInput::placeholder {
  color: #A9AFB7;
}
/*
[data-placeholder]:empty:before{
  content: attr(data-placeholder);
  color: #A9AFB7;
}
*/
.accessRulesContainer {
  margin-bottom: -10px;
  display: flex;
  flex: 1;
  align-items: center;
  font-size: 14px;
}
.hoverLink:hover {
  text-decoration: underline;
  cursor: pointer;
}
.postboxShareContainer {
  display: flex;
  justify-content: flex-end;
  padding: 0.75rem;
  padding-top: 0px;
}

.postboxShareContainerBtn {
  display: inline-flex;
  cursor: pointer;
  font-family: "Inter";
  align-items: center;
  border-radius: 3px;
  border: 1px solid transparent;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-size: 15px;
  font-weight: 500;
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.postboxReplyContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.5rem;
  padding-bottom: 0px;
  padding-top: 0.25rem;
  font-size: 13px;
}
.postboxReplyContainer span {
  font-size: 13px;
}

.postboxReplyBadge {
  font-weight: 500;
  border-radius: 0.375rem;
  padding: 0.25rem;
}
.postboxGatingTextMobile {
  display: none;
}
.postboxGatingTextDesktop {
  display: inline-block;
}

/** Rules for mobiles */
@media (max-width: 600px) {
  .postboxUserContainer {
    display: none;
  }
  .postboxGatingTextMobile {
    display: inline-block;
  }
  .postboxGatingTextDesktop {
    display: none;
  }
}

.loadingContainer {
  width: 100%;
  justify-content: center;
  display: flex;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.mentionsBoxContainer {
  position: absolute;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  top: 60px;
  left: 10px;
  width: 250px;
  z-index: 50;
  border-radius: 0.5rem;
  overflow: visible;
  --tw-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border: 1px solid;
}
.mentionsBoxInputContainer {
  border-radius: 0.5rem 0.5rem 0 0;
  overflow: hidden;
  width: 100%;
  display: flex;
}
.mentionsBoxEmptyState {
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
  font-size: 13px;
  font-family: "Inter";
  padding-left: 25px;
  padding-right: 25px;
}
.userResults {
  padding: 0.5rem;
}
.userResultContainer {
  display: flex;
  flex-direction: row;
  margin-top: 0.5rem;
}
