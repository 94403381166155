.commentsGlobalContainer {
  display: flex;
  flex-direction: column;
  border-radius: 0.375rem;
  width: 100%;
  border-width: 1px;
  height: 100%;
}

.commentsContainer {
  display: flex;
  flex-direction: column-reverse;
  flex: 1;
  border-style: solid;
  border-top-width: 0px;
  border-bottom-width: 1px;
  border-left-width: 0;
  border-right-width: 0px;
  padding: 1.5rem;
  container-type: inline-size;
  overflow-y: scroll;
}
.commentsContainer > :not([hidden]):first-child {
  margin-top: 1.75rem;
}
.commentsContainer > :not([hidden]) ~ :not([hidden]):last-child {
  margin-top: 0px;
}
.commentsContainer > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.75rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.75rem * var(--tw-space-y-reverse));
}
.postboxContainer {
  display: flex;
  padding: 1rem;
}
.notificationsBanner {
  cursor: pointer;
  display: flex;
  padding: 0.65rem;
  border-width: 1px;
  border-style: solid;
}
.notificationsBanner:hover {
  text-decoration: underline;
}
.notificationsBannerText {
  padding: 5px;
  display: flex;
  flex-direction: row;
}
.loadingContainer {
  width: 100%;
  justify-content: center;
  display: flex;
  padding: 2rem;
}

.commentsEmptyStateContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.greyLine {
  position: absolute;
  margin-left: -1px;
  border-radius: 0.375rem;
}

.footerContainer {
  padding: 1.5rem;
  width: 100%;
}

.footerOpenSocialContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  cursor: pointer;
}
.footerOpenSocialContainer:hover {
  text-decoration: underline;
  text-underline-offset: 4px;
}
