.input, .textarea {
  appearance: none;
  outline: none;
  display: block;
  width: 100%;
  border-radius: 0.375rem;
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  padding-top: 0.5rem;
  padding-right: 0.75rem;
  padding-bottom: 0.5rem;
  padding-left: 0.75rem;
  border-width: 1px;
  line-height: 1.5rem;
  border: 1px solid;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.input:focus, .textarea:focus {
  border-color: #93bcf7 !important;
}
