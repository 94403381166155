.connectBtn {
  display: inline-flex;
  cursor: pointer;
  font-family: "Inter";
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  border: 1px solid transparent;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  font-size: 15px;
  font-weight: 500;
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
