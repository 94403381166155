.postContainer {
  display: flex;
  align-items: flex-start;
  position: relative;
  flex-direction: column;
}
.replyToContainer {
  display: flex;
  margin-bottom: 5px;
  opacity: 0.8;
  margin-left: 10px;
  align-items: flex-start;
}
.replyToContainer:hover {
  opacity: 1;
}
.replyToContainer .postContent p {
  display: inline-block !important;
}
.linkReply {
  border-top: 2px solid #000;
  border-left: 2px solid #000;
  border-radius: 6px 0 0 0;
  width: 15px;
  height: 20px;
  display: flex;
  align-self: flex-end;
  margin-left: 19px;
}
.postDetailsContainer {
  min-width: 0px;
  flex-direction: column;
  flex: 1;
  margin-left: 12px;
}

.postDetailsContainerMetadata {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.postDetailsContainerUser {
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
}

.postDetailsContainerUsername {
  display: flex;
  font-weight: 500;
}

.postDetailsContainerTimestamp {
  display: flex;
  justify-self: flex-end;
  font-size: 12px;
  font-weight: 400;
  margin: 0;
  margin-right: 0.5rem;
}
.postDetailsContainerTimestamp span, .postDetailsContainerTimestamp a {
  font-size: 12px;
  font-weight: 400;
}

.postReplyCta {
  display: inline-flex;
  font-family: "Inter";
  align-items: center;
  border-radius: 9999px;
  border: 1px solid transparent;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 4px;
  padding-bottom: 4px;
  font-size: 13px;
  font-weight: 500;
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.postContent {
  overflow-wrap: break-word;
  margin: 0px;
}
.postContent p {
  margin: 0px;
  line-height: inherit !important;
}
.postViewMoreCtaContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 0.2rem;
  margin-left: -47px;
}
.postActionsContainer {
  display: flex;
  flex-direction: row;
  margin-top: 0.5rem;
}
.postActionButton {
  display: inline-flex;
  align-items: center;
  border-radius: 0.375rem;
  border: 1px solid transparent;
  background: transparent;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  font-size: 13px;
  font-weight: 500;
  font-family: "Inter";
  cursor: pointer;
}

.postUrlMetadataContainer {
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  cursor: pointer;
  border-radius: 0.5rem;
  border: 1px solid;
  overflow: hidden;
  margin-top: 0.5rem;
}
.postUrlMetadataContainer:hover {
  --tw-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.postUrlMetadataImage {
  background-size: cover;
  width: 100%;
  height: 263px;
  background-position: 50%;
  object-fit: cover;
}

.postUrlMetadataDetails {
  padding: 1rem;
  border-top: 1px solid;
}
.postUrlMetadataDetails p {
  margin: 0;
}
.postUrlMetadataDetails > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.25rem * var(--tw-space-y-reverse));
}
.postMenuContainer {
  position: absolute;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  z-index: 50;
  border-radius: 0.5rem;
  overflow: hidden;
  min-width: 200px;
  --tw-drop-shadow: drop-shadow(0 4px 3px rgb(0 0 0 / 0.07)) drop-shadow(0 2px 2px rgb(0 0 0 / 0.06));
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  border: 1px solid;
  font-size: 13px;
}
.postMenuContainer > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.5rem * var(--tw-space-y-reverse));
}

/** Rules for mobiles */
@container (max-width: 600px) {
  .hideMobile {
    display: none;
  }
  .postContent {
    max-width: 100%;
    width: 100%;
    padding-right: 0 !important;
  }
  .postUrlMetadataContainer {
    margin-left: -57px;
  }
  .postDetailsContainerTimestamp {
    margin-right: 0px;
  }
  .postUrlMetadataImage {
    height: 140px;
  }
}

/** Prose advanced styling to support markdown */
.postContent :where([class~="lead"]):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-lead);
  font-size: 1.25em;
  line-height: 1.6;
  margin-top: 1.2em;
  margin-bottom: 1.2em;
}
.postContent :where(a):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-links);
  text-decoration: underline;
  font-weight: 500;
}
.postContent :where(strong):not(:where([class~="not-prose"] *)) {
  color: inherit;
  font-weight: 500;
}
.postContent :where(a strong):not(:where([class~="not-prose"] *)) {
  color: inherit;
}
.postContent :where(blockquote strong):not(:where([class~="not-prose"] *)) {
  color: inherit;
}
.postContent :where(thead th strong):not(:where([class~="not-prose"] *)) {
  color: inherit;
}
.postContent :where(ol):not(:where([class~="not-prose"] *)) {
  list-style-type: decimal;
  margin-top: 1.25em;
  margin-bottom: 1.25em;
  padding-left: 1.625em;
}
.postContent :where(ol[type="A"]):not(:where([class~="not-prose"] *)) {
  list-style-type: upper-alpha;
}
.postContent :where(ol[type="a"]):not(:where([class~="not-prose"] *)) {
  list-style-type: lower-alpha;
}
.postContent :where(ol[type="A" s]):not(:where([class~="not-prose"] *)) {
  list-style-type: upper-alpha;
}
.postContent :where(ol[type="a" s]):not(:where([class~="not-prose"] *)) {
  list-style-type: lower-alpha;
}
.postContent :where(ol[type="I"]):not(:where([class~="not-prose"] *)) {
  list-style-type: upper-roman;
}
.postContent :where(ol[type="i"]):not(:where([class~="not-prose"] *)) {
  list-style-type: lower-roman;
}
.postContent :where(ol[type="I" s]):not(:where([class~="not-prose"] *)) {
  list-style-type: upper-roman;
}
.postContent :where(ol[type="i" s]):not(:where([class~="not-prose"] *)) {
  list-style-type: lower-roman;
}
.postContent :where(ol[type="1"]):not(:where([class~="not-prose"] *)) {
  list-style-type: decimal;
}
.postContent :where(ul):not(:where([class~="not-prose"] *)) {
  list-style-type: disc;
  margin-top: 1.25em;
  margin-bottom: 1.25em;
  padding-left: 1.625em;
}
.postContent :where(ol > li):not(:where([class~="not-prose"] *))::marker {
  font-weight: 400;
  color: inherit;
}
.postContent :where(ul > li):not(:where([class~="not-prose"] *))::marker {
  color: inherit;
}
.postContent :where(hr):not(:where([class~="not-prose"] *)) {
  border-color: var(--tw-prose-hr);
  border-top-width: 1px;
  margin-top: 3em;
  margin-bottom: 3em;
}
.postContent :where(blockquote):not(:where([class~="not-prose"] *)) {
  font-weight: 500;
  font-style: italic;
  color: inherit;
  border-left-width: 0.25rem;
  border-left-color: var(--tw-prose-quote-borders);
  quotes: "\201C""\201D""\2018""\2019";
  margin-top: 1.6em;
  margin-bottom: 1.6em;
  padding-left: 1em;
}
.postContent :where(blockquote p:first-of-type):not(:where([class~="not-prose"] *))::before {
  content: open-quote;
}
.postContent :where(blockquote p:last-of-type):not(:where([class~="not-prose"] *))::after {
  content: close-quote;
}
.postContent :where(h1):not(:where([class~="not-prose"] *)) {
  color: inherit;
  font-weight: 800;
  font-size: 2.25em;
  margin-top: 0;
  margin-bottom: 0.8888889em;
  line-height: 1.1111111;
}
.postContent :where(h1 strong):not(:where([class~="not-prose"] *)) {
  font-weight: 900;
  color: inherit;
}
.postContent :where(h2):not(:where([class~="not-prose"] *)) {
  color: inherit;
  font-weight: 700;
  font-size: 1.5em;
  margin-top: 2em;
  margin-bottom: 1em;
  line-height: 1.3333333;
}
.postContent :where(h2 strong):not(:where([class~="not-prose"] *)) {
  font-weight: 800;
  color: inherit;
}
.postContent :where(h3):not(:where([class~="not-prose"] *)) {
  color: inherit;
  font-weight: 600;
  font-size: 1.25em;
  margin-top: 1.6em;
  margin-bottom: 0.6em;
  line-height: 1.6;
}
.postContent :where(h3 strong):not(:where([class~="not-prose"] *)) {
  font-weight: 700;
  color: inherit;
}
.postContent :where(h4):not(:where([class~="not-prose"] *)) {
  color: inherit;
  font-weight: 600;
  margin-top: 1.5em;
  margin-bottom: 0.5em;
  line-height: 1.5;
}
.postContent :where(h4 strong):not(:where([class~="not-prose"] *)) {
  font-weight: 700;
  color: inherit;
}
.postContent :where(img):not(:where([class~="not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}
.postContent :where(figure > *):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
  margin-bottom: 0;
}
.postContent :where(figcaption):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-captions);
  font-size: 0.875em;
  line-height: 1.4285714;
  margin-top: 0.8571429em;
}
.postContent :where(code):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-code);
  font-weight: 600;
  font-size: 0.875em;
}
.postContent :where(code):not(:where([class~="not-prose"] *))::before {
  content: "`";
}
.postContent :where(code):not(:where([class~="not-prose"] *))::after {
  content: "`";
}
.postContent :where(a code):not(:where([class~="not-prose"] *)) {
  color: inherit;
}
.postContent :where(h1 code):not(:where([class~="not-prose"] *)) {
  color: inherit;
}
.postContent :where(h2 code):not(:where([class~="not-prose"] *)) {
  color: inherit;
  font-size: 0.875em;
}
.postContent :where(h3 code):not(:where([class~="not-prose"] *)) {
  color: inherit;
  font-size: 0.9em;
}
.postContent :where(h4 code):not(:where([class~="not-prose"] *)) {
  color: inherit;
}
.postContent :where(blockquote code):not(:where([class~="not-prose"] *)) {
  color: inherit;
}
.postContent :where(thead th code):not(:where([class~="not-prose"] *)) {
  color: inherit;
}
.postContent :where(pre):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-pre-code);
  background-color: var(--tw-prose-pre-bg);
  overflow-x: auto;
  font-weight: 400;
  font-size: 0.875em;
  line-height: 1.7142857;
  margin-top: 1.7142857em;
  margin-bottom: 1.7142857em;
  border-radius: 0.375rem;
  padding-top: 0.8571429em;
  padding-right: 1.1428571em;
  padding-bottom: 0.8571429em;
  padding-left: 1.1428571em;
}
.postContent :where(pre code):not(:where([class~="not-prose"] *)) {
  background-color: transparent;
  border-width: 0;
  border-radius: 0;
  padding: 0;
  font-weight: inherit;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
}
.postContent :where(pre code):not(:where([class~="not-prose"] *))::before {
  content: none;
}
.postContent :where(pre code):not(:where([class~="not-prose"] *))::after {
  content: none;
}
.postContent :where(table):not(:where([class~="not-prose"] *)) {
  width: 100%;
  table-layout: auto;
  text-align: left;
  margin-top: 2em;
  margin-bottom: 2em;
  font-size: 0.875em;
  line-height: 1.7142857;
}
.postContent :where(thead):not(:where([class~="not-prose"] *)) {
  border-bottom-width: 1px;
  border-bottom-color: var(--tw-prose-th-borders);
}
.postContent :where(thead th):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  font-weight: 600;
  vertical-align: bottom;
  padding-right: 0.5714286em;
  padding-bottom: 0.5714286em;
  padding-left: 0.5714286em;
}
.postContent :where(tbody tr):not(:where([class~="not-prose"] *)) {
  border-bottom-width: 1px;
  border-bottom-color: var(--tw-prose-td-borders);
}
.postContent :where(tbody tr:last-child):not(:where([class~="not-prose"] *)) {
  border-bottom-width: 0;
}
.postContent :where(tbody td):not(:where([class~="not-prose"] *)) {
  vertical-align: baseline;
}
.postContent :where(tfoot):not(:where([class~="not-prose"] *)) {
  border-top-width: 1px;
  border-top-color: var(--tw-prose-th-borders);
}
.postContent :where(tfoot td):not(:where([class~="not-prose"] *)) {
  vertical-align: top;
}

.postContent {
  --tw-prose-body: #374151;
  --tw-prose-headings: #111827;
  --tw-prose-lead: #4b5563;
  --tw-prose-links: #111827;
  --tw-prose-bold: #111827;
  --tw-prose-counters: #6b7280;
  --tw-prose-bullets: #d1d5db;
  --tw-prose-hr: #e5e7eb;
  --tw-prose-quotes: #111827;
  --tw-prose-quote-borders: #e5e7eb;
  --tw-prose-captions: #6b7280;
  --tw-prose-code: #111827;
  --tw-prose-pre-code: #e5e7eb;
  --tw-prose-pre-bg: #1f2937;
  --tw-prose-th-borders: #d1d5db;
  --tw-prose-td-borders: #e5e7eb;
  --tw-prose-invert-body: #d1d5db;
  --tw-prose-invert-headings: #fff;
  --tw-prose-invert-lead: #9ca3af;
  --tw-prose-invert-links: #fff;
  --tw-prose-invert-bold: #fff;
  --tw-prose-invert-counters: #9ca3af;
  --tw-prose-invert-bullets: #4b5563;
  --tw-prose-invert-hr: #374151;
  --tw-prose-invert-quotes: #f3f4f6;
  --tw-prose-invert-quote-borders: #374151;
  --tw-prose-invert-captions: #9ca3af;
  --tw-prose-invert-code: #fff;
  --tw-prose-invert-pre-code: #d1d5db;
  --tw-prose-invert-pre-bg: rgb(0 0 0 / 50%);
  --tw-prose-invert-th-borders: #4b5563;
  --tw-prose-invert-td-borders: #374151;
  font-size: 1rem;
  line-height: 1.75;
}
.postContent :where(p):not(:where([class~="not-prose"] *)) {
  margin-top: 1.25em;
  margin-bottom: 1.25em;
}
.postContent :where(video):not(:where([class~="not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}
.postContent :where(figure):not(:where([class~="not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}
.postContent :where(li):not(:where([class~="not-prose"] *)) {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}
.postContent :where(ol > li):not(:where([class~="not-prose"] *)) {
  padding-left: 0.375em;
}
.postContent :where(ul > li):not(:where([class~="not-prose"] *)) {
  padding-left: 0.375em;
}
.postContent :where(.postContent > ul > li p):not(:where([class~="not-prose"] *)) {
  margin-top: 0.75em;
  margin-bottom: 0.75em;
}
.postContent :where(.postContent > ul > li > *:first-child):not(:where([class~="not-prose"] *)) {
  margin-top: 1.25em;
}
.postContent :where(.postContent > ul > li > *:last-child):not(:where([class~="not-prose"] *)) {
  margin-bottom: 1.25em;
}
.postContent :where(.postContent > ol > li > *:first-child):not(:where([class~="not-prose"] *)) {
  margin-top: 1.25em;
}
.postContent :where(.postContent > ol > li > *:last-child):not(:where([class~="not-prose"] *)) {
  margin-bottom: 1.25em;
}
.postContent :where(ul ul, ul ol, ol ul, ol ol):not(:where([class~="not-prose"] *)) {
  margin-top: 0.75em;
  margin-bottom: 0.75em;
}
.postContent :where(hr + *):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
}
.postContent :where(h2 + *):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
}
.postContent :where(h3 + *):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
}
.postContent :where(h4 + *):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
}
.postContent :where(thead th:first-child):not(:where([class~="not-prose"] *)) {
  padding-left: 0;
}
.postContent :where(thead th:last-child):not(:where([class~="not-prose"] *)) {
  padding-right: 0;
}
.postContent :where(tbody td, tfoot td):not(:where([class~="not-prose"] *)) {
  padding-top: 0.5714286em;
  padding-right: 0.5714286em;
  padding-bottom: 0.5714286em;
  padding-left: 0.5714286em;
}
.postContent :where(tbody td:first-child, tfoot td:first-child):not(:where([class~="not-prose"] *)) {
  padding-left: 0;
}
.postContent :where(tbody td:last-child, tfoot td:last-child):not(:where([class~="not-prose"] *)) {
  padding-right: 0;
}
.postContent :where(.postContent > :first-child):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
}
.postContent :where(.postContent > :last-child):not(:where([class~="not-prose"] *)) {
  margin-bottom: 0;
}
.postContent {
  font-size: 0.875rem;
  line-height: 1.7142857;
}
.postContent :where(p):not(:where([class~="not-prose"] *)) {
  margin-top: 1.1428571em;
  margin-bottom: 1.1428571em;
}
.postContent :where([class~="lead"]):not(:where([class~="not-prose"] *)) {
  font-size: 1.2857143em;
  line-height: 1.5555556;
  margin-top: 0.8888889em;
  margin-bottom: 0.8888889em;
}
.postContent :where(blockquote):not(:where([class~="not-prose"] *)) {
  margin-top: 1.3333333em;
  margin-bottom: 1.3333333em;
  padding-left: 1.1111111em;
}
.postContent :where(h1):not(:where([class~="not-prose"] *)) {
  font-size: 2.1428571em;
  margin-top: 0;
  margin-bottom: 0.8em;
  line-height: 1.2;
}
.postContent :where(h2):not(:where([class~="not-prose"] *)) {
  font-size: 1.4285714em;
  margin-top: 1.6em;
  margin-bottom: 0.8em;
  line-height: 1.4;
}
.postContent :where(h3):not(:where([class~="not-prose"] *)) {
  font-size: 1.2857143em;
  margin-top: 1.5555556em;
  margin-bottom: 0.4444444em;
  line-height: 1.5555556;
}
.postContent :where(h4):not(:where([class~="not-prose"] *)) {
  margin-top: 1.4285714em;
  margin-bottom: 0.5714286em;
  line-height: 1.4285714;
}
.postContent :where(img):not(:where([class~="not-prose"] *)) {
  margin-top: 1.7142857em;
  margin-bottom: 1.7142857em;
}
.postContent :where(video):not(:where([class~="not-prose"] *)) {
  margin-top: 1.7142857em;
  margin-bottom: 1.7142857em;
}
.postContent :where(figure):not(:where([class~="not-prose"] *)) {
  margin-top: 1.7142857em;
  margin-bottom: 1.7142857em;
}
.postContent :where(figure > *):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
  margin-bottom: 0;
}
.postContent :where(figcaption):not(:where([class~="not-prose"] *)) {
  font-size: 0.8571429em;
  line-height: 1.3333333;
  margin-top: 0.6666667em;
}
.postContent :where(code):not(:where([class~="not-prose"] *)) {
  font-size: 0.8571429em;
}
.postContent :where(h2 code):not(:where([class~="not-prose"] *)) {
  font-size: 0.9em;
}
.postContent :where(h3 code):not(:where([class~="not-prose"] *)) {
  font-size: 0.8888889em;
}
.postContent :where(pre):not(:where([class~="not-prose"] *)) {
  font-size: 0.8571429em;
  line-height: 1.6666667;
  margin-top: 1.6666667em;
  margin-bottom: 1.6666667em;
  border-radius: 0.25rem;
  padding-top: 0.6666667em;
  padding-right: 1em;
  padding-bottom: 0.6666667em;
  padding-left: 1em;
}
.postContent :where(ol):not(:where([class~="not-prose"] *)) {
  margin-top: 1.1428571em;
  margin-bottom: 1.1428571em;
  padding-left: 1.5714286em;
}
.postContent :where(ul):not(:where([class~="not-prose"] *)) {
  margin-top: 1.1428571em;
  margin-bottom: 1.1428571em;
  padding-left: 1.5714286em;
}
.postContent :where(li):not(:where([class~="not-prose"] *)) {
  margin-top: 0.2857143em;
  margin-bottom: 0.2857143em;
}
.postContent :where(ol > li):not(:where([class~="not-prose"] *)) {
  padding-left: 0.4285714em;
}
.postContent :where(ul > li):not(:where([class~="not-prose"] *)) {
  padding-left: 0.4285714em;
}
.postContent :where(.postContent > ul > li p):not(:where([class~="not-prose"] *)) {
  margin-top: 0.5714286em;
  margin-bottom: 0.5714286em;
}
.postContent :where(.postContent > ul > li > *:first-child):not(:where([class~="not-prose"] *)) {
  margin-top: 1.1428571em;
}
.postContent :where(.postContent > ul > li > *:last-child):not(:where([class~="not-prose"] *)) {
  margin-bottom: 1.1428571em;
}
.postContent :where(.postContent > ol > li > *:first-child):not(:where([class~="not-prose"] *)) {
  margin-top: 1.1428571em;
}
.postContent :where(.postContent > ol > li > *:last-child):not(:where([class~="not-prose"] *)) {
  margin-bottom: 1.1428571em;
}
.postContent :where(ul ul, ul ol, ol ul, ol ol):not(:where([class~="not-prose"] *)) {
  margin-top: 0.5714286em;
  margin-bottom: 0.5714286em;
}
.postContent :where(hr):not(:where([class~="not-prose"] *)) {
  margin-top: 2.8571429em;
  margin-bottom: 2.8571429em;
}
.postContent :where(hr + *):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
}
.postContent :where(h2 + *):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
}
.postContent :where(h3 + *):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
}
.postContent :where(h4 + *):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
}
.postContent :where(table):not(:where([class~="not-prose"] *)) {
  font-size: 0.8571429em;
  line-height: 1.5;
}
.postContent :where(thead th):not(:where([class~="not-prose"] *)) {
  padding-right: 1em;
  padding-bottom: 0.6666667em;
  padding-left: 1em;
}
.postContent :where(thead th:first-child):not(:where([class~="not-prose"] *)) {
  padding-left: 0;
}
.postContent :where(thead th:last-child):not(:where([class~="not-prose"] *)) {
  padding-right: 0;
}
.postContent :where(tbody td, tfoot td):not(:where([class~="not-prose"] *)) {
  padding-top: 0.6666667em;
  padding-right: 1em;
  padding-bottom: 0.6666667em;
  padding-left: 1em;
}
.postContent :where(tbody td:first-child, tfoot td:first-child):not(:where([class~="not-prose"] *)) {
  padding-left: 0;
}
.postContent :where(tbody td:last-child, tfoot td:last-child):not(:where([class~="not-prose"] *)) {
  padding-right: 0;
}
.postContent :where(.postContent > :first-child):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
}
.postContent :where(.postContent > :last-child):not(:where([class~="not-prose"] *)) {
  margin-bottom: 0;
}
