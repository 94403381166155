.LoadingCircle {
  animation: spin 1s linear infinite;
  margin-left: -0.25rem;
  height: 1.25rem;
  width: 1.25rem;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
