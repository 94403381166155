.accessRulesContainer {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 75%;
}
.accessRuleContainer {
  display: flex;
  border: 1px dashed;
  padding: 1rem;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  margin-bottom: 10px;
  flex-wrap: wrap;
}
@media (max-width: 600px) {
  .accessRuleContainer {
    flex-direction: column;
  }
}
.operator {
  font-family: "Inter";
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 10px;
  text-align: center;
  width: 100%;
  text-transform: uppercase;
}
