.btnPrimary {
  display: flex;
  align-items: center;
  border-radius: 9999px;
  border: 1px solid transparent;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-weight: 500;
  cursor: pointer;
}
/*shadow-sm hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 cursor-pointer*/
