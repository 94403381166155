.tabsChainsWraper {
  display: flex;
  justify-content: center;
}
.tabsChainsContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  background: #f3f4f6;
  border-radius: 9999px;
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  margin-top: 0.25rem;
  padding: 0.25rem;
}

.tabsChain {
  border-radius: 9999px;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  cursor: pointer;
  font-size: 13px;
  font-weight: 500;
}

.loadingContainer {
  width: 100%;
  color: #111827;
  display: flex;
  justify-content: center;
  margin-top: 1.5rem;
}
.nftsContainer {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(3, minmax(0,1fr));
  grid-gap: 1rem;
  gap: 1rem;
  margin-top: 1rem;
}
.nftsEmptyState {
  width: 100%;
  border-radius: 0.375rem;
  background: #f8fafc;
  color: #0f172a;
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  margin-top: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  text-align: center;
  font-family: "Inter";
}
.nftContainer {
  display: flex;
  flex-direction: column;
  position: relative;
}
.nftContainer img {
  object-fit: cover;
  max-width: 100%;
  height: auto;
}
.nftContainer p {
  display: block;
  margin: 0;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.nftImageContainer {
  width: 100%;
  display: block;
  position: relative;
  overflow: hidden;
  --tw-bg-opacity: 1;
  border-radius: 0.5rem;
}

.nftOverlayContainer {
  position: absolute;
  z-index: 50;
  top: 0px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.7);
}
.nftOverlayText {
  display: inline-flex;
  color: #FFF;
  margin-bottom: 0.25rem;
  font-size: 13px;
  text-align: center;
  width: 100%;
  font-weight: 500;
}
